import React from 'react';
import EvaluationAuth from '../screens/auth/EvaluationAuth';
import { Redirect, Router } from '@reach/router';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../services/firebase';
import MatchesAuth from '../screens/auth/MatchesAuth';
import { graphql } from 'gatsby';
import { DiagnosisQuery } from '../graphql-types';
import DiagnosisAuth from '../screens/auth/DiagnosisAuth';

export interface DiagnosisTemplateProps {
  data: DiagnosisQuery;
}

const DiagnosisTemplate: React.FunctionComponent<DiagnosisTemplateProps> = ({
  data,
}): JSX.Element => {
  const [user, loading, error] = useAuthState(getFirebaseAuth());

  if (loading) {
    return <></>;
  }

  return user ? <DiagnosisAuth data={data} /> : <Redirect noThrow to="/" />;
};

export default DiagnosisTemplate;

export const pageQuery = graphql`
  query Diagnosis($id: String!) {
    contentfulDiagnosis(id: { eq: $id }) {
      area
      severityLevel
      body {
        json
      }
      video {
        file {
          url
        }
      }
    }
  }
`;
