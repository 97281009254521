import React, { useState } from 'react';
import styled from 'styled-components';
import SphereStatic, { SphereWrapper } from './SphereStatic';
import Popover from 'react-popover';
import { theme } from '../../theme';

const Container = styled.div``;

const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.p`
  font-size: 2rem;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.666rem;
  color: grey;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  top: -16rem;
`;

const InfoBox = styled.div`
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  border-radius: 4em;
  box-shadow: rgba(22, 53, 76, 0.05) 0 7px 25px 0;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  height: 45px;
  margin: 1rem 1rem 2rem 1rem;
  padding: 0 24px 0 6px;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  display: flex;
  align-items: center;
  min-width: 17ch;
`;

const InfoBoxButton = styled.div<{ colour: string }>`
  -webkit-font-smoothing: antialiased;
  background-color: ${props => props.colour};
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 0.2rem;
  margin-top: 0;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  text-transform: uppercase;
`;

const InfoBoxText = styled.div`
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: #0f2137;
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  font-family: Montserrat;
  font-weight: 700;
  text-align: center;
  flex: 1;
`;

const InfoWrapper = styled.div`
  -webkit-font-smoothing: antialiased;
  text-align: left;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  display: flex;
  justify-content: center;
`;

const Heading = styled.h1`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 4rem;
`;

export interface ScoreObject {
  severity: number;
  animated?: boolean;
  faded?: boolean;
  unknown?: boolean;
}

export interface WellbeingRowProps {
  anxiety: ScoreObject;
  depression: ScoreObject;
  stress: ScoreObject;
  minorityStress: ScoreObject;
  noKey?: boolean;
  linkToResults?: boolean;
  scale?: number;
}

const WellbeingRow: React.FunctionComponent<WellbeingRowProps> = ({
  minorityStress,
  depression,
  anxiety,
  stress,
  noKey,
  linkToResults,
  scale,
}) => {
  const ORIGINAL_SCALE = scale || 0.2;

  return (
    <Container>
      <CenterBox>
        <SphereStatic
          severity={anxiety.severity}
          number={anxiety.unknown ? '' : anxiety.severity}
          category="anxiety"
          originalScale={ORIGINAL_SCALE}
          secondDelay={0}
          faded={anxiety.faded}
          animated={anxiety.animated}
          linked={!anxiety.unknown ? linkToResults : undefined}
        />

        <SphereStatic
          severity={depression.severity}
          number={depression.unknown ? '' : depression.severity}
          category="depression"
          originalScale={ORIGINAL_SCALE}
          secondDelay={0}
          faded={depression.faded}
          animated={depression.animated}
          linked={!depression.unknown ? linkToResults : undefined}
        />

        <SphereStatic
          severity={stress.severity}
          number={stress.unknown ? '' : stress.severity}
          category="stress"
          originalScale={ORIGINAL_SCALE}
          secondDelay={0}
          faded={stress.faded}
          animated={stress.animated}
          linked={!stress.unknown ? linkToResults : undefined}
        />

        <SphereStatic
          severity={minorityStress.severity}
          number={minorityStress.unknown ? '' : minorityStress.severity}
          category="minorityStress"
          originalScale={ORIGINAL_SCALE}
          secondDelay={0}
          faded={minorityStress.faded}
          animated={minorityStress.animated}
          linked={!minorityStress.unknown ? linkToResults : undefined}
        />
      </CenterBox>
      {!noKey && (
        <InfoWrapper>
          <InfoBox>
            <InfoBoxButton colour={theme.colors.anxiety} />
            <InfoBoxText>Anxiety</InfoBoxText>
          </InfoBox>
          <InfoBox>
            <InfoBoxButton colour={theme.colors.depression} />
            <InfoBoxText>Depression</InfoBoxText>
          </InfoBox>
          <InfoBox>
            <InfoBoxButton colour={theme.colors.stress} />
            <InfoBoxText>Stress</InfoBoxText>
          </InfoBox>
          <InfoBox>
            <InfoBoxButton colour={theme.colors.minorityStress} />
            <InfoBoxText>Minority Stress</InfoBoxText>
          </InfoBox>
        </InfoWrapper>
      )}
    </Container>
  );
};

export default WellbeingRow;
