import React from 'react';
import { pickBy, toPairs } from 'lodash-es';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import { Link, navigate } from 'gatsby';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { DiagnosisQuery, Query } from '../../graphql-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { articleRichTextOptions } from '../../utils/contentful';
import { isBrowser } from '../../services/auth';
import 'firebase/auth';
import 'firebase/firestore';
import { Redirect } from '@reach/router';
import WellbeingRow, {
  WellbeingRowProps,
} from '../../components/WellbeingSpheres/WellbeingRow';
import colors from '../../theme/colors';
import { theme } from '../../theme';
import Text from '../../reusecore/src/elements/Text/text';
import Box from '../../reusecore/src/elements/Box';
import { darken, lighten } from 'polished';
import Button from '../../reusecore/src/elements/Button/button';

export interface DiagnosisAuthProps {
  data: DiagnosisQuery;
}

const AREA_CASE_MAP = {
  Anxiety: 'anxiety',
  Depression: 'depression',
  Stress: 'stress',
  'Minority Stress': 'minority stress',
};

const AREA_SLUG_MAP = {
  Anxiety: 'anxiety',
  Depression: 'depression',
  Stress: 'stress',
  'Minority Stress': 'minority',
};

const CATEGORY_COLOUR_MAP: { [index: string]: [string, string] } = {
  Anxiety: [
    lighten(0.1, theme.colors.anxiety),
    darken(0.1, theme.colors.anxiety),
  ],
  Depression: [
    lighten(0.1, theme.colors.depression),
    darken(0.1, theme.colors.depression),
  ],
  Stress: [lighten(0.1, theme.colors.stress), darken(0.1, theme.colors.stress)],
  'Minority Stress': [
    lighten(0.1, theme.colors.minorityStress),
    darken(0.1, theme.colors.minorityStress),
  ],
};

const InfoBox = styled.div<{ bg?: string }>`
  -webkit-font-smoothing: antialiased;
  background-color: ${props => props.bg || '#fff'};
  border-radius: 4em;
  box-shadow: rgba(22, 53, 76, 0.05) 0 7px 25px 0;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  height: 45px;
  margin: 1rem 1rem 2rem 1rem;
  padding: 0 24px 0 6px;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  display: inline-flex;
  align-items: center;
  width: 22ch;
  cursor: pointer;
`;

const InfoBoxButton = styled.div<{ colour: string }>`
  -webkit-font-smoothing: antialiased;
  background-color: ${props => props.colour};
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 0.6rem;
  margin-top: 0;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  text-transform: uppercase;
`;

const InfoBoxText = styled.p`
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: #0f2137;
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
  font-family: Montserrat;
  font-weight: 700;
  text-align: center;
`;

const DiagnosisAuth: React.FunctionComponent<DiagnosisAuthProps> = ({
  data,
}): JSX.Element => {
  // Destructure data
  const {
    body: { json },
    area,
    severityLevel,
    video: {
      file: { url },
    },
  } = data.contentfulDiagnosis;

  if (!isBrowser()) {
    return <Redirect to="/" noThrow />;
  }

  const richTextComponent = documentToReactComponents(
    json,
    articleRichTextOptions
  );

  const WellbeingRowInfo: WellbeingRowProps = {
    anxiety: {
      severity: area === 'Anxiety' ? parseInt(severityLevel) : 1,
      faded: area !== 'Anxiety',
      animated: area === 'Anxiety',
      unknown: area !== 'Anxiety',
    },
    depression: {
      severity: area === 'Depression' ? parseInt(severityLevel) : 1,
      faded: area !== 'Depression',
      animated: area === 'Depression',
      unknown: area !== 'Depression',
    },
    stress: {
      severity: area === 'Stress' ? parseInt(severityLevel) : 1,
      faded: area !== 'Stress',
      animated: area === 'Stress',
      unknown: area !== 'Stress',
    },
    minorityStress: {
      severity: area === 'Minority Stress' ? parseInt(severityLevel) : 1,
      faded: area !== 'Minority Stress',
      animated: area === 'Minority Stress',
      unknown: area !== 'Minority Stress',
    },
  };

  return (
    <Layout contrast barsOnly>
      <Container
        fluid
        style={{
          background: `linear-gradient(90deg, ${CATEGORY_COLOUR_MAP[area][0]}, ${CATEGORY_COLOUR_MAP[area][1]})`,
        }}
      >
        <Container className="pt-sm-5 pt-4">
          <Row style={{ paddingBottom: theme.space[4] }}>
            <Col className="d-sm-flex" md={6} style={{ position: 'relative' }}>
              <Box
                position="absolute"
                width="300%"
                height="300%"
                borderRadius="50%"
                bg={theme.colors.wellbeingTrans}
                bottom="-25%"
                right="-10%"
              />
              <Text
                position={['relative', 'absolute']}
                pr="15%"
                fontFamily="heading"
                fontSize={[4, 7]}
                fontWeight={3}
                lineHeight="title"
              >
                Here are your {AREA_CASE_MAP[area]} test results
              </Text>
            </Col>
            <Col className="d-sm-none d-flex" md={12} style={{ paddingTop: 0 }}>
              <Card className="py-0 py-sm-2 " style={{ width: '100%' }}>
                <CardHeader
                  style={{ fontWeight: 700, fontFamily: 'Montserrat' }}
                >
                  Wellbeing Indicator
                </CardHeader>
                <CardBody>
                  <WellbeingRow scale={0.1} {...WellbeingRowInfo} noKey />
                </CardBody>
              </Card>
            </Col>
            <Col className="d-none d-sm-flex" md={6} style={{ paddingTop: 0 }}>
              <Card className="py-0 " style={{ width: '100%' }}>
                <CardHeader
                  style={{ fontWeight: 700, fontFamily: 'Montserrat' }}
                >
                  Wellbeing Indicator
                </CardHeader>
                <CardBody>
                  <WellbeingRow {...WellbeingRowInfo} noKey />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row
            style={{
              paddingTop: theme.space[4],
              paddingBottom: theme.space[4],
            }}
          >
            <Col className="text-left">
              <Card>
                <CardHeader
                  style={{ fontWeight: 700, fontFamily: 'Montserrat' }}
                >
                  Your Results
                </CardHeader>
                <CardBody>{richTextComponent}</CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ paddingBottom: theme.space[4] }}>
            <Col className="text-left">
              <Card>
                <CardHeader
                  style={{ fontWeight: 700, fontFamily: 'Montserrat' }}
                >
                  More Tests
                </CardHeader>
                <CardBody>
                  <Box
                    flexBox
                    flexDirection={['column', 'row']}
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    {toPairs<string>(
                      pickBy<string>(AREA_SLUG_MAP, (v, k) => k !== area)
                    ).map<JSX.Element>(x => (
                      <Link to={`/evaluation/${x[1]}`}>
                        <InfoBox bg={lighten(0.45, theme.colors.secondary)}>
                          <InfoBoxButton
                            colour={CATEGORY_COLOUR_MAP[x[0]][0]}
                          />
                          <InfoBoxText>{x[0]}</InfoBoxText>
                        </InfoBox>
                      </Link>
                    ))}
                  </Box>
                  <Box flexBox justifyContent="center" py={3}>
                    <Button
                      px={4}
                      onClick={() => {
                        navigate('/user');
                      }}
                    >
                      User Home
                    </Button>
                  </Box>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
};

export default DiagnosisAuth;
