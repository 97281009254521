import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import getFirebase from './firebase';

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () => {
  if (isBrowser()) {
    getFirebase();
    return firebase.auth().currentUser;
  }
  return null;
};

export const isLoggedIn = () => {
  if (isBrowser()) {
    getFirebase();
    if (firebase.auth().currentUser) {
      return true;
    }
    firebase.auth().onAuthStateChanged(user => {
      return !!user;
    });
  }
  return false;
};
